// Toolbars

.default-toolbar {
	--background: var(--ion-color-default);
	--color: var(--ion-color-default-contrast);
}

.basic-toolbar {
	--background: var(--menu-color);
	--color: var(--menu-text-color);
}
