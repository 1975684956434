// Aligns

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

// Floats

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.bold {
	font-weight: bold;
}

.italic {
	font-weight: italic;
}

// Labels

.basic-label {
	--color: var(--title-color);
	color: var(--title-color);
}

.basic-default-labels {
	--color: var(--ion-color-default);
	color: var(--ion-color-default);
}

.emoji-label {
	font-size: 29px;
}

.login-title {
	font-family: "Roobert-Bold";
	font-size: 1.6em;
	text-align: center;
}

.invalid-labels {
	font-size: 0.8em;
	color: #dd2222;
	font-weight: bold;
	text-align: center;
}

.primary-title {
	font-size: 22px;
	font-weight: bold;
}

.secondary-title {
	font-size: 18px;
	font-weight: 500;
}

// Link

.basic-link {
	--color: var(--link-color);
	color: var(--link-color);
}
