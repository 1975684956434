// Menu

ion-split-pane {
	--side-max-width: 295px !important;
}

.menu-content {
	width: 100%;
}

.menu-closed {
	--width: 90px !important;
	width: 90px !important;
	--min-width: 90px !important;
	min-width: 90px !important;
	--max-width: 90px !important;
	max-width: 90px !important;
	// transition: all 0.2s ease-in-out 0s;

	.menu-header {
		display: flex;
		flex-direction: column;
	}

	.menu-items {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: var(--font);
		font-weight: 600;
	}

	.menu-icon {
		font-size: 1.4em;
	}
}

.menu-footer {
	display: flex !important;
	height: 100px;

	> .btns-log-back {
		display: flex !important;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> ion-button {
			height: 40px !important;
		}

		span {
			font-family: var(--font);
			font-weight: 600;
			text-transform: none;
			min-width: 120px;
			margin-left: 8px;
		}
	}
}

.btn-menu-lang {
	border: 1px solid;
	border-radius: 16px;
	width: 105%;

	> .lang-name {
		font-size: 12px;
		margin: 0 6px 0 3px;
	}

	> ion-icon {
		width: 12px;
		height: 12px;
	}
}

.menu-user-avatar {
	width: 44px;
	height: 44px;
	border: 2px solid transparent;
	padding: 1px;
	margin-left: 10px;
	cursor: pointer;
	position: relative;

	img {
		width: 42px;
		height: 100% !important;
	}

	> .profile-picture {
		width: 38px;
		height: 38px;
		border-radius: 50%;
		object-fit: cover;
		object-position: center;
		margin: 0 auto;
		background: rgb(234, 239, 242);
		padding: 11px;

		> .cus-picture {
			width: 14px;
			height: 14px;
			margin: auto;
			border-radius: 0;
			opacity: 0.6;
		}
	}

	#profile-badge {
		position: absolute;
		top: 0px;
		right: -5px;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		font-size: 9px;
		font-family: var(--font);
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		--background: var(--active-menu-item);
	}
}

.btn-notifs-menu {
	position: relative;
	display: block !important;

	ion-icon {
		position: relative;
		width: 1.5rem;
		height: 1.5rem;
	}

	div {
		width: 0.65rem;
		height: 0.65rem;
		border-radius: 50%;
		position: absolute;
		color: transparent;
		z-index: 999999;
		background: var(--menu-active-color);
	}

	#chat-badge-menu {
		// right: 10.5%;
		right: 16%;
		top: 5%;
	}

	#notify-badge-menu {
		right: 0;
		top: 5%;
	}
}

.auth-buttons {
	ion-button {
		width: 50%;
		height: 3rem;
		font-family: var(--font);
		font-weight: 600;
		font-size: 0.8rem;
		border-radius: 50px;
	}
}

.menu-btn-style {
	cursor: pointer;

	.left-bar {
		--padding-start: 0;
	}
	ion-item {
		height: 57px;
	}
	ion-item:hover {
		opacity: 0.7;
	}

	.menu-icon {
		font-size: 1.1em;
	}

	.menu-icon-img {
		width: 1.1rem;
		height: 1.06rem;
	}

	.menu-list-items {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;

		ion-label {
			font-family: var(--font);
		}
	}

	// .menu-list-items:hover {
	// 	opacity: 0.7;
	// }

	.menu-item-active {
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		width: 5px;
		height: 100%;
		margin-right: 11px;
		font-family: var(--font);
		font-weight: 700;
	}
}
