.full-sized-modal .modal-wrapper {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	display: block !important;
	width: 100vw !important;
	height: 100vh !important;
}

ion-modal[class*="full-sized-modal"] {
	--width: 100% !important;
	--height: 100% !important;
}

ion-modal[class*="confirm-register-modal"] {
	--width: 45% !important;
	--height: auto !important;
	--border-radius: 20px !important;
}

ion-modal[class*="accompanying-register-modal"] {
	--width: 40% !important;
	--height: 80% !important;
	--border-radius: 20px !important;
}

ion-modal[class*="card-exchange-form-modal-css"] {
	--width: 40% !important;
	--height: 80% !important;
	--border-radius: 20px !important;
}

ion-modal[class*="card-exchange-form-modal-css-mobile"] {
	--width: 40% !important;
	--height: 80% !important;
	--border-radius: 20px !important;
}

ion-modal[class*="confirm-ics-download-modal"] {
	--width: 35% !important;
	--height: 350px !important;
}

ion-modal[class*="confirm-ics-download-modal-mobile"] {
	--width: 100% !important;
	--height: 45% !important;
}

ion-modal[class*="confirm-ics-download-modal-schedule"] {
	--width: 35% !important;
	--height: 350px !important;
}

ion-modal[class*="confirm-ics-download-modal-mobile-schedule"] {
	--width: 100% !important;
	--height: 45% !important;
}

ion-modal[class*="invite-accompanying-modal"] {
	--width: 400px !important;
	--min-width: 350px !important;
	--max-width: 450px !important;
	--height: 220px !important;
	--min-height: 210px !important;
	--max-height: 250px !important;
	--border-radius: 20px !important;
	--backdrop-opacity: 0.1 !important;
	--box-shadow: beige !important;
}

ion-modal[class*="invite-accompanying-modal--mobile"] {
	--width: 350px !important;
	--min-width: 300px !important;
	--max-width: 400px !important;
	--height: 220px !important;
	--min-height: 210px !important;
	--max-height: 250px !important;
	--border-radius: 20px !important;
	--backdrop-opacity: 0.1 !important;
	--box-shadow: beige !important;
}

.sized-modal-80 .modal-wrapper {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	display: block !important;
	width: 80vw !important;
	height: 80vh !important;
}

ion-modal[class*="sized-modal-80"] {
	--width: 80% !important;
	--height: 80% !important;
}
