// Wrap
.f-wrap {
	flex-wrap: wrap;
}

// Rows

.f-row-c-c {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.f-row-c-fe {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.f-row-c-fs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.f-row-fs-c {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
}

.f-row-c-sb {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.f-row-fs-fs {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}

// Cols

.f-col {
	display: flex;
	flex-direction: column;
}

.f-col-c-c {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.f-col-c-fs {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.f-col-fs-c {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.f-col-fs-fs {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.f-col-c-sb {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
