.default-color {
	color: var(--ion-color-default);
}

.contrast-color {
	color: var(--ion-color-default-contrast);
}

.red-color {
	color: #c91f1f !important;
}

// Background

.back-default {
	background-color: var(--ion-color-default);
}

.back-white {
	background-color: white;
}
