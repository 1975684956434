/** Ionic CSS Variables **/
// :root {
// 	--login-btn-bg-color: #3c11dd;
// 	--login-btn-color: #ffffff;
// 	--valid-btn-bg-color: #0acb15;
// 	--valid-btn-color: #ffffff;
// 	--title-color: #201157;
// 	--bg-color: #f6f3ff;
// 	--bg-color-content: #f6f3ff66;
// 	--btn-bg-color-hover: #0acb15d9; //meme couleur que --valid-btn-bg-color en ajoutant 'd9' à la fin
// 	// --btn-bg-color-hover: rgba(var(--valid-btn-bg-color), 0.85);
// 	--text-color-1st-login: #ffffff;
// 	--text-color-2nd-login-desktop: #201157;
// 	--text-color-2nd-login-mobile: #ffffff;
// 	--label-login-color: #908b99;
// 	--active-menu-item: #eb266a;
// 	--font: "Roobert-Regular";

// 	/** default **/
// 	--ion-color-default: #2973a2;
// 	--ion-color-default-rgb: 56, 128, 255;
// 	--ion-color-default-contrast: #ffffff;
// 	--ion-color-default-contrast-rgb: 255, 255, 255;
// 	--ion-color-default-shade: #2083c0;
// 	--ion-color-default-tint: #639ebc;

// 	/** primary **/
// 	--ion-color-primary: #3880ff;
// 	--ion-color-primary-rgb: 56, 128, 255;
// 	--ion-color-primary-contrast: #ffffff;
// 	--ion-color-primary-contrast-rgb: 255, 255, 255;
// 	--ion-color-primary-shade: #3171e0;
// 	--ion-color-primary-tint: #4c8dff;

// 	/** secondary **/
// 	--ion-color-secondary: #0cd1e8;
// 	--ion-color-secondary-rgb: 12, 209, 232;
// 	--ion-color-secondary-contrast: #ffffff;
// 	--ion-color-secondary-contrast-rgb: 255, 255, 255;
// 	--ion-color-secondary-shade: #0bb8cc;
// 	--ion-color-secondary-tint: #24d6ea;

// 	/** tertiary **/
// 	--ion-color-tertiary: #7044ff;
// 	--ion-color-tertiary-rgb: 112, 68, 255;
// 	--ion-color-tertiary-contrast: #ffffff;
// 	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
// 	--ion-color-tertiary-shade: #633ce0;
// 	--ion-color-tertiary-tint: #7e57ff;

// 	/** success **/
// 	--ion-color-success: #10dc60;
// 	--ion-color-success-rgb: 16, 220, 96;
// 	--ion-color-success-contrast: #ffffff;
// 	--ion-color-success-contrast-rgb: 255, 255, 255;
// 	--ion-color-success-shade: #0ec254;
// 	--ion-color-success-tint: #28e070;

// 	/** warning **/
// 	--ion-color-warning: #ffce00;
// 	--ion-color-warning-rgb: 255, 206, 0;
// 	--ion-color-warning-contrast: #ffffff;
// 	--ion-color-warning-contrast-rgb: 255, 255, 255;
// 	--ion-color-warning-shade: #e0b500;
// 	--ion-color-warning-tint: #ffd31a;

// 	/** danger **/
// 	--ion-color-danger: #f04141;
// 	--ion-color-danger-rgb: 245, 61, 61;
// 	--ion-color-danger-contrast: #ffffff;
// 	--ion-color-danger-contrast-rgb: 255, 255, 255;
// 	--ion-color-danger-shade: #d33939;
// 	--ion-color-danger-tint: #f25454;

// 	/** dark **/
// 	--ion-color-dark: #222428;
// 	--ion-color-dark-rgb: 34, 34, 34;
// 	--ion-color-dark-contrast: #ffffff;
// 	--ion-color-dark-contrast-rgb: 255, 255, 255;
// 	--ion-color-dark-shade: #1e2023;
// 	--ion-color-dark-tint: #383a3e;

// 	/** medium **/
// 	--ion-color-medium: #989aa2;
// 	--ion-color-medium-rgb: 152, 154, 162;
// 	--ion-color-medium-contrast: #ffffff;
// 	--ion-color-medium-contrast-rgb: 255, 255, 255;
// 	--ion-color-medium-shade: #86888f;
// 	--ion-color-medium-tint: #a2a4ab;

// 	/** light **/
// 	--ion-color-light: #f4f5f8;
// 	--ion-color-light-rgb: 244, 244, 244;
// 	--ion-color-light-contrast: #000000;
// 	--ion-color-light-contrast-rgb: 0, 0, 0;
// 	--ion-color-light-shade: #d7d8da;
// 	--ion-color-light-tint: #f5f6f9;

// 	/** others **/
// 	--toolbar-background: transparent;
// 	--radio-ios-disabled-opacity: 1;
// 	--radio-md-disabled-opacity: 1;
// 	--radio-wp-disabled-opacity: 1;
// 	--action-sheet-ios-text-align: start;
// 	--text-input-ios-input-clear-icon-color: #ffffff;
// 	--checkbox-ios-disabled-opacity: 1;
// 	--checkbox-md-disabled-opacity: 1;

// 	ion-back-button,
// 	ion-menu-button {
// 		display: block;
// 	}
// }

/* Importing Bootstrap SCSS file. */
@import "../../node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
// @import "../../node_modules/ngx-bootstrap/datepicker/bs-datepicker";
