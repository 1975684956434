.btn-anim-shake {
	animation-name: btn-anim-shake;
	animation-duration: 4s;
	animation-iteration-count: infinite;
	backface-visibility: hidden;
	transform: translateZ(0);
	-webkit-font-smoothing: subpixel-antialiased;
	will-change: transform;
}

@keyframes btn-anim-shake {
	0%,
	10% {
		transform: translate3d(-1px, 0, 0);
	}
	2%,
	8% {
		transform: translate3d(2px, 0, 0);
	}
	4%,
	6% {
		transform: translate3d(-1px, 0, 0);
	}
	5% {
		transform: translate3d(2px, 0, 0);
	}
	11% {
		transform: translate3d(0, 0, 0);
	}
}
