.loader-container {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	z-index: 9999999999;
}

// loader
.loader-default {
	width: 45px;
	height: 45px;
	display: inline-flex;
	padding: 0px;
	border-radius: 100%;
	border: 5px solid;
	border-color: var(--menu-color);
	border-style: dashed;
	// border-top-color: var(--menu-color);
	// border-bottom-color: rgba(var(--menu-color), 0.3);
	// border-left-color: rgba(var(--menu-color), 0.7);
	// border-right-color: rgba(var(--menu-color), 0.3);
	-webkit-animation: loader-default 1s ease-in-out infinite;
	animation: loader-default 1s ease-in-out infinite;
}

.loader-main {
	width: 45px;
	height: 45px;
	display: inline-flex;
	padding: 0px;
	border-radius: 100%;
	border: 5px solid;
	border-color: var(--ion-color-default);
	border-style: dashed;
	// border-top-color: var(--menu-color);
	// border-bottom-color: rgba(var(--menu-color), 0.3);
	// border-left-color: rgba(var(--menu-color), 0.7);
	// border-right-color: rgba(var(--menu-color), 0.3);
	-webkit-animation: loader-default 1s ease-in-out infinite;
	animation: loader-default 1s ease-in-out infinite;
}

.align-loader {
	margin-top: 15px;
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

@keyframes loader-default {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes loader-default {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

.background-loader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 180px;
	max-height: 130px;
	margin: auto;
	padding: 20px 40px;
	background-color: var(--menu-text-color);
	border-radius: 20px;
	border: 3px solid var(--menu-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.loading-label {
		color: var(--menu-color);
		font-weight: bold;
	}
}
