// Inputs

.custom-input {
	color: var(--label-login-color);
	padding-left: 10px !important;
	height: 3rem;
	margin: auto;
	display: inline-block;
	border-radius: 5rem;
}

.material-date .mdc-text-field {
	border: 1px solid #e1ddec;
	border-radius: 5rem;
	// background-color: transparent !important;

	.mdc-line-ripple {
		display: none !important;
	}
}

.input-item {
	border: 1px solid #e1ddec;
	border-radius: 5rem;
	max-height: 56px;
	ion-label {
		font-family: var(--font);
		font-weight: 400;
		margin-top: -8px !important;
	}
	ion-input {
		display: inline-block;
		padding-left: 10px !important;
		padding-right: 25px !important;
		max-height: 41px;
	}
	ion-icon {
		margin: auto 0px auto 7px;
		width: 16px;
		height: 16px;
	}
}

.email-input {
	text-transform: lowercase;
}

.input-label-text {
	color: #908b99;
}

// Star css on 5 stars
input.star-5:checked ~ label.star:before {
	text-shadow: none !important;
}
