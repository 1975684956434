.btn-default {
	margin: 0 auto;
	display: block;
	width: 100%;
	// max-width: 500px;
	height: 3rem;
}

.btn-validate-primary {
	color: var(--btn-validate-primary-color);
	background-color: var(--btn-validate-primary-bg-color);
}

.btn-validate-secondary {
	color: var(--btn-validate-primary-bg-color);
	background-color: var(--btn-validate-primary-color);
	border-color: var(--btn-validate-primary-bg-color);
	border-width: 4px;
	border-style: solid;
}

.basic-button {
	width: 100%;
	max-width: 500px;
	--background: var(--btn-bg-color);
	--color: var(--btn-text-color);
	--border-radius: 15px;
}

.basic-fab-button {
	--background: var(--btn-bg-color);
	--color: var(--btn-text-color);
}

.basic-icon-button {
	--background: var(--btn-bg-color);
	background: var(--btn-bg-color);
	--color: var(--btn-text-color);
	color: var(--btn-text-color);
}

.basic-icon-transparent-header-button {
	--background: transparent;
	background: transparent;
	--color: var(--menu-text-color);
	color: var(--menu-text-color);
	--box-shadow: none;
}

.basic-transparent-icon-button {
	--background: transparent;
	background: transparent;
	--color: var(--btn-text-color);
	color: var(--btn-text-color);
	--box-shadow: none;
	--ripple-color: transparent;
}

.login-button {
	--background: var(--login);
}

.btn-outline-default {
	--background: var(--login-btn-bg-color);
	color: var(--login-btn-color);
	--border-color: var(--login-btn-color);
}

.access-btn {
	--background: var(--valid-btn-bg-color);
	--background-hover: var(--btn-bg-color-hover);
	--background-focused: var(--btn-bg-color-hover);
	color: var(--valid-btn-color);
}

.pwd-btn {
	--background: transparent;
	--box-shadow: none;
	margin: auto;
	--background-hover: #80808024;
	--background-focused: #80808024;
}

.legal-btn {
	margin-top: 3%;
	margin-bottom: 3%;
	width: 100%;
	display: flex;
	justify-content: center;
	font-family: var(--font);
	font-weight: 700;
	cursor: pointer;

	.btn-legal-custom {
		background: transparent !important;
		font-size: 1em;
		letter-spacing: 0.09rem;
		text-align: center;
		text-decoration: underline;
	}
}

.filter-button-container {
	position: fixed;
	z-index: 999;
	bottom: 15px;
	left: 50%;
	transform: translate(-50%, -50%);

	.filter-button {
		height: 45px;
		margin: 0;
		font-family: var(--font);
		font-weight: 700;
		font-size: 12px;
		--padding-bottom: 10px;
		--padding-top: 10px;
	}

	.filter-button-badge {
		position: fixed;
		top: -7px;
		right: -7px;
		font-family: var(--font);
		font-weight: 700;
		font-size: 10px;
		padding: 5px 9px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background: var(--menu-active-color);
		color: var(--menu-text-color);
		border-radius: 100%;
	}
}
