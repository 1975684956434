.basic-logo {
	width: 150px;
	margin: 0 auto;
	display: block;
}

.banner-logo {
	width: 100%;
	margin: 0 auto;
	display: block;
	max-width: 600px;
}

.full-width-banner {
	width: 100%;
	max-height: 210px;
	object-fit: contain;
	object-position: top;
}
