// Icons

.icons-25 {
	font-size: 25px;
	cursor: pointer;
}

.icons-30 {
	font-size: 30px;
	cursor: pointer;
}

.icons-40 {
	font-size: 40px;
	cursor: pointer;
}

.arrow {
	border: solid black;
	border-width: 0 4px 4px 0;
	display: inline-block;
	padding: 3px;
	border-radius: 2px;
}

.right {
	transform: rotate(-45deg) translateX(-2px) translateY(-2px);
	-webkit-transform: rotate(-45deg) translateX(-2px) translateY(-2px);
}

.left {
	transform: rotate(135deg) translateX(-1px) translateY(-1px);
	-webkit-transform: rotate(135deg) translateX(-1px) translateY(-1px);
}

.up {
	transform: rotate(-135deg) translateX(-1px) translateY(-1px);
	-webkit-transform: rotate(-135deg) translateX(-1px) translateY(-1px);
}

.down {
	transform: rotate(45deg) translateX(-1px) translateY(-1px);
	-webkit-transform: rotate(45deg) translateX(-1px) translateY(-1px);
}
