.font-roobert {
	font-family: "Roobert-Regular";
}

// CircularStd
@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-Black.otf") format("opentype");
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-BlackItalic.otf") format("opentype");
	font-weight: bolder;
	font-style: italic;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-Bold.otf") format("opentype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-BoldItalic.otf") format("opentype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-Book.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-BookItalic.otf") format("opentype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-Medium.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("/assets/fonts/CircularStd/CircularStd-MediumItalic.otf") format("opentype");
	font-weight: normal;
	font-style: italic;
}
