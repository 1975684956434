// Cards

.basic-card {
	padding: 0;
	border-radius: 0;
}

.full-width-card {
	padding: 0;
	border-radius: 0;
	width: 100%;
}

.card-content {
	margin: 0 auto;
	width: 100%;
	position: relative;
	z-index: 9999;
}
