.hide-scrollbar {
	-ms-overflow-style: none !important; /* IE and Edge */
	scrollbar-width: none !important; /* Firefox */
	&::-webkit-scrollbar {
		display: none !important; /*Chrome, Safari & Opera */
	}
}

.inner-scroll {
	-ms-overflow-style: none !important; /* IE and Edge */
	scrollbar-width: none !important; /* Firefox */
	&::-webkit-scrollbar {
		display: none !important; /*Chrome, Safari & Opera */
	}
}

.no-scrollbar {
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
}

.no-scrollbar::-webkit-scrollbar {
	display: none !important;
}
