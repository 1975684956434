/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use "@angular/material" as mat;

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "../node_modules/@ctrl/ngx-emoji-mart/picker.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@import "./theme/animations.scss";
@import "./theme/borders.scss";
@import "./theme/buttons.scss";
@import "./theme/cards.scss";
@import "./theme/colors.scss";
@import "./theme/flex.scss";
@import "./theme/fonts.scss";
@import "./theme/forms.scss";
@import "./theme/grids.scss";
@import "./theme/hover.scss";
@import "./theme/icons.scss";
@import "./theme/imgs.scss";
@import "./theme/inputs.scss";
@import "./theme/labels.scss";
@import "./theme/loaders.scss";
@import "./theme/menu.scss";
@import "./theme/modals.scss";
@import "./theme/paddings-margins.scss";
@import "./theme/position.scss";
@import "./theme/rows-cols.scss";
@import "./theme/scrollbars.scss";
@import "./theme/searchbars.scss";
@import "./theme/snackbars.scss";
@import "./theme/toggles.scss";
@import "./theme/toolbars.scss";
@import "./theme/width-height.scss";
@import "./theme/roboto.scss";
// @import "/node_modules/flag-icons/css/flag-icons.min.css";

@import "./theme.scss";
@include mat.core();

$backoffice-b3-primary: mat.m2-define-palette($b3app-primary);
$backoffice-b3-accent: mat.m2-define-palette($b3app-primary);
$backoffice-b3-warn: mat.m2-define-palette($b3app-primary); //mat.m2-define-palette($mat-red);

$light-theme: mat.m2-define-light-theme(
	(
		background: mat.m2-get-color-from-palette($backoffice-b3-primary, "500-contrast"),
		primary: mat.m2-define-palette($backoffice-b3-primary),
		accent: mat.m2-define-palette($backoffice-b3-accent),
		warn: mat.m2-define-palette($backoffice-b3-warn),
		color: (
			background: mat.m2-get-color-from-palette($backoffice-b3-primary, "500-contrast"),
			primary: mat.m2-define-palette($backoffice-b3-primary),
			accent: mat.m2-define-palette($backoffice-b3-accent),
			warn: mat.m2-define-palette($backoffice-b3-warn)
		)
	)
);

@include mat.all-component-themes($light-theme);
@include mat.core-color($light-theme);
@include mat.button-color($light-theme);

// @tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
ion-app {
	height: 100%;
	background-color: white;
}
body,
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

youtube-player {
	iframe {
		border-radius: 10px;
	}
}

.ngxImageZoomContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.ngxImageZoomThumbnail {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.cdk-virtual-scroll-content-wrapper {
	// position: relative !important;
	// height: 100% !important;
	width: 100% !important;
}

.cdk-virtual-scroll-spacer {
	// display: none !important;
}

.page {
	--background: rgba(0, 0, 0, 0);
}

.background {
	width: 100%;
	min-height: 100% !important;
	// min-height: 100vh !important;
	background: #fff;
}

.divider-default {
	margin-top: 12px;
}

.cursor-pointer {
	cursor: pointer;
}

.clickable {
	cursor: pointer;
}

.clickable:hover {
	opacity: 0.8;
}

.alert-radio-label {
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.separator {
	width: 100%;
	height: 1px;
	background: black;
	margin: 10px 0px;
}

.header-md::after {
	display: none;
}

.filter-modal-css {
	--border-radius: 48px 48px 0 0;
	--background: rgba(255, 255, 255, 255);
	padding-top: 11%;
}

.auto-height-modal-css {
	// --border-radius: 48px 48px 0 0;
	--background: rgba(255, 255, 255, 255);
	--height: auto;
}

.line-clamp-2 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.ellipsis-100 {
	white-space: nowrap;
	text-overflow: ellipsis !important;
	overflow: hidden !important;
	max-width: 100ch;
}

.ellipsis-15 {
	white-space: nowrap;
	text-overflow: ellipsis !important;
	overflow: hidden !important;
	max-width: 15ch;
}

.ellipsis-25 {
	white-space: nowrap;
	text-overflow: ellipsis !important;
	overflow: hidden !important;
	max-width: 25ch;
}

@media screen and (min-width: 768px) {
	.background {
		width: 70%;
		margin: 0 auto;
	}

	.pz-zoom-button {
		top: 0;
		right: 0;
		left: unset !important;
		bottom: unset !important;
	}

	// CSS V1
	.toolbar-title-in-small-screen {
		display: none;
	}

	.toolbar-title-in-big-screen {
		display: block;
	}

	.color-2nd-screen {
		color: var(--text-color-2nd-login-desktop);

		a {
			color: var(--text-color-2nd-login-desktop) !important;
		}
	}

	ion-title {
		text-align: left;
		position: relative;

		* {
			text-align: left;
		}
	}
	// FIN CSS V1
}

/* unsupported browser */
.unsupported_browser {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	h1 {
		font-size: 45px;
		font-weight: 600;
	}

	a {
		cursor: pointer;
	}

	.b3app_logo {
		margin-top: 2%;
		width: 200px;
		height: 200px;
	}

	p {
		text-align: center;
		width: 40%;
	}

	.chome_logo {
		width: 50px;
		height: 50px;
	}
}

.toast-message::part(container) {
	background: var(--btn-bg-color);
	color: var(--btn-text-color);
}

.center-alert-items {
	.alert-button-group {
		display: flex;
		justify-content: center;
	}
}

@media screen and (min-width: 768px) {
	.my-custom-alert .alert-wrapper {
		min-width: 450px !important;
		border-radius: 10px !important;
		.alert-head {
			text-align: center;
		}
	}
}

.multiple-select-alert {
	.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
		.alert-checkbox-group {
			max-height: 400px;
			button {
				.alert-button-inner {
					.alert-checkbox-label {
						text-overflow: ellipsis;
						overflow: hidden;
						display: -webkit-box !important;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						white-space: normal;
						height: 55px;
					}
				}
			}
		}
	}
}

.single-select-alert {
	.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
		.alert-radio-group {
			max-height: 400px;
			button {
				.alert-button-inner {
					.alert-radio-label {
						text-overflow: ellipsis;
						overflow: hidden;
						display: -webkit-box !important;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						white-space: normal;
						height: 55px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.single-select-alert {
		.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
			min-width: 1100px;
		}
	}
	.multiple-select-alert {
		.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
			min-width: 1100px;
		}
	}
}

.my-custom-alert .alert-wrapper .alert-head {
	text-align: center;
}

@keyframes form_button_spinner {
	to {
		transform: rotate(360deg);
	}
}

.form-button-spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 36%;
	right: 0;
	width: 35px;
	height: 35px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	border-top-color: #000000;
	animation: button_spinner 0.8s linear infinite;
	z-index: 999;
}

@keyframes button_spinner {
	to {
		transform: rotate(360deg);
	}
}

.button-spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 36%;
	left: 49.5%;
	width: 35px;
	height: 35px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	border-top-color: #000000;
	animation: button_spinner 0.8s linear infinite;
	z-index: 999;
}

.auto-generate-button-spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 37%;
	right: 10px;
	width: 25px;
	height: 25px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	border-top-color: #000000;
	animation: button_spinner 0.8s linear infinite;
	z-index: 999;
}

.schedule-spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 36%;
	left: 49.5%;
	z-index: 999;
	width: 25px;
	height: 25px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	border-top-color: #000000;
	animation: schedule_spinner 0.8s linear infinite;
}

.fab-button-spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 38%;
	left: 38.5%;
	width: 35px;
	height: 35px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	border-top-color: #000000;
	animation: button_spinner 0.8s linear infinite;
	z-index: 999;
}

.fab-schedule-spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 38%;
	left: 38.5%;
	width: 35px;
	height: 35px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	border-top-color: #000000;
	animation: button_spinner 0.8s linear infinite;
	z-index: 999;
}

@keyframes schedule_spinner {
	to {
		transform: rotate(360deg);
	}
}

.input-item,
.textarea-item {
	background-color: #fff;
}
.input-item::part(native),
.textarea-item::part(native) {
	background-color: transparent;
	border-radius: inherit;
}

// Swiper

.swiper-slide {
	display: block !important;
}

.hide-menu-and-header {
	ion-menu {
		display: none !important;
	}

	app-header {
		display: none !important;
	}
	// }

	ion-router-outlet {
		height: 100vh !important;
	}
}

.show-menu-and-header {
	ion-menu {
		display: block !important;
	}

	app-header {
		display: block !important;
	}
}

.tel-input {
	border-radius: 3px !important;
}

input[type="tel"] {
	width: 100%;
	height: 65px;
	position: relative;
	top: 5px;
	padding-left: 70px !important;
	padding-top: 10px;
	padding-right: 5px;
	background: white;
	border-radius: 25px !important;
	color: #000000;
	border: 1px solid #dad7d7;
}

.tox-notifications-container {
	display: none !important;
}

ion-checkbox {
	label {
		.checkbox-label-placement-end .label-text-wrapper {
			--margin-inline-start: 8px !important;
		}
	}
}
