.full-w {
	width: 100% !important;
}

.full-h {
	height: 100%;
}

.min-vh-80 {
	min-height: 80vh;
}

.min-vh-100 {
	min-height: 100vh;
}

.w-80 {
	width: 80%;
}

.scroll {
	overflow: scroll !important;
}

.scroll-y-only {
	overflow-x: hidden !important;
	overflow-y: scroll !important;
}

.full-vh {
	height: 100vh;
}

.full-vw {
	width: 100vw;
}
