.searchbar-input {
	border: 0 !important;
	box-shadow: none !important;
	padding-inline-start: 30px !important;
}

ion-searchbar {
	.searchbar-input {
		border-radius: 28px !important;
	}

	input {
		box-shadow: none;
	}
}

.searchbar-search-icon {
	display: none !important;
}
