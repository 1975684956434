// Snackbar

.error-snackbar {
	background-color: #c32402 !important;
	color: white !important;
	border-color: #9b1c01 !important;
}

.success-snackbar {
	background-color: #007c15 !important;
	color: white !important;
	border-color: #005e10 !important;
}

.warning-snackbar {
	background-color: #d0d011 !important;
	color: rgb(42, 41, 41) !important;
	border-color: #b4ca14 !important;
}
