// Margins

.no-margins {
	margin: 0;
}

// Top

.m-t-5 {
	margin-top: 5px;
}

.m-t-10 {
	margin-top: 10px;
}

.m-t-20 {
	margin-top: 20px;
}

.m-t-40 {
	margin-top: 40px;
}

.m-t-60 {
	margin-top: 60px;
}

.m-t-80 {
	margin-top: 80px;
}

.m-t-100 {
	margin-top: 100px;
}

.m-t-150 {
	margin-top: 150px;
}

.m-t-200 {
	margin-top: 200px;
}

// Bottom

// Left

.m-b-5 {
	margin-bottom: 5px;
}

.m-b-10 {
	margin-bottom: 10px;
}

.m-b-20 {
	margin-bottom: 20px;
}

.m-b-40 {
	margin-bottom: 40px;
}

.m-b-60 {
	margin-bottom: 60px;
}

.m-b-80 {
	margin-bottom: 80px;
}

.m-b-100 {
	margin-bottom: 100px;
}

.m-b-150 {
	margin-bottom: 150px;
}

.m-b-200 {
	margin-bottom: 200px;
}

// Left

.m-l-5 {
	margin-left: 5px;
}

.m-l-10 {
	margin-left: 10px;
}

.m-l-15 {
	margin-left: 15px;
}

.m-l-20 {
	margin-left: 20px;
}

.m-l-40 {
	margin-left: 40px;
}

.m-l-60 {
	margin-left: 60px;
}

.m-l-80 {
	margin-left: 80px;
}

.m-l-100 {
	margin-left: 100px;
}

.m-l-150 {
	margin-left: 150px;
}

.m-l-200 {
	margin-left: 200px;
}

// Right

.m-r-5 {
	margin-right: 5px;
}

.m-r-10 {
	margin-right: 10px;
}

.m-r-15 {
	margin-right: 15px;
}

.m-r-20 {
	margin-right: 20px;
}

.m-r-40 {
	margin-right: 40px;
}

.m-r-60 {
	margin-right: 60px;
}

.m-r-80 {
	margin-right: 80px;
}

.m-r-100 {
	margin-right: 100px;
}

.m-r-150 {
	margin-right: 150px;
}

.m-r-200 {
	margin-right: 200px;
}

// All

.m-a-5 {
	margin: 5px;
}

.m-a-10 {
	margin: 10px;
}

.m-a-20 {
	margin: 20px;
}

.m-a-40 {
	margin: 40px;
}

.m-a-60 {
	margin: 60px;
}

.m-a-80 {
	margin: 80px;
}

.m-a-100 {
	margin: 100px;
}

.m-a-150 {
	margin: 150px;
}

.m-a-200 {
	margin: 200px;
}

// Paddings

.no-paddings {
	padding: 0;
}

// Top

.p-t-5 {
	padding-top: 5px;
}

.p-t-10 {
	padding-top: 10px;
}

.p-t-15 {
	padding-top: 15px;
}

.p-t-20 {
	padding-top: 20px;
}

.p-t-40 {
	padding-top: 40px;
}

.p-t-60 {
	padding-top: 60px;
}

.p-t-80 {
	padding-top: 80px;
}

.p-t-100 {
	padding-top: 100px;
}

.p-t-150 {
	padding-top: 150px;
}

.p-t-200 {
	padding-top: 200px;
}

// Bottom

// Left

.p-b-5 {
	padding-bottom: 5px;
}

.p-b-10 {
	padding-bottom: 10px;
}

.p-b-20 {
	padding-bottom: 20px;
}

.p-b-40 {
	padding-bottom: 40px;
}

.p-b-60 {
	padding-bottom: 60px;
}

.p-b-80 {
	padding-bottom: 80px;
}

.p-b-100 {
	padding-bottom: 100px;
}

.p-b-150 {
	padding-bottom: 150px;
}

.p-b-200 {
	padding-bottom: 200px;
}

// Left

.p-l-5 {
	padding-left: 5px;
}

.p-l-10 {
	padding-left: 10px;
}

.p-l-20 {
	padding-left: 20px;
}

.p-l-40 {
	padding-left: 40px;
}

.p-l-60 {
	padding-left: 60px;
}

.p-l-80 {
	padding-left: 80px;
}

.p-l-100 {
	padding-left: 100px;
}

.p-l-150 {
	padding-left: 150px;
}

.p-l-200 {
	padding-left: 200px;
}

// Right

.p-r-5 {
	padding-right: 5px;
}

.p-r-10 {
	padding-right: 10px;
}

.p-r-20 {
	padding-right: 20px;
}

.p-r-40 {
	padding-right: 40px;
}

.p-r-60 {
	padding-right: 60px;
}

.p-r-80 {
	padding-right: 80px;
}

.p-r-100 {
	padding-right: 100px;
}

.p-r-150 {
	padding-right: 150px;
}

.p-r-200 {
	padding-right: 200px;
}
